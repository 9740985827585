
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

export default defineComponent({
  name: 'Qrcode',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();
    const tableOpts = {
      search: {
        fetch: true,
        criteria: {
          cols: []
        },
        remote: {
          action: https?.facade.pageQrcode
        }
      },
      cols: [
        {
          field: 'title',
          label: '标题'
        },
        {
          field: 'type',
          label: '分类'
        },
        {
          field: 'sort',
          label: '排序'
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        },
        {
          field: 'imgHeight',
          label: '背景图片高度'
        },
        {
          field: 'imgWidth',
          label: '背景图片宽度'
        },
        {
          field: 'imgUrl',
          label: '背景图片',
          ability: 'preview'
        },
        {
          field: 'qrcodeUrl',
          label: '二维码',
          ability: 'preview'
        },
        {
          field: 'remark',
          label: '备注'
        },
        {
          field: 'createTime',
          label: '创建时间'
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'title',
              label: '标题',
              required: true
            },
            {
              field: 'type',
              label: '分类',
              required: true
            },
            {
              field: 'sort',
              label: '排序',
              type: 'number',
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableStr,
              required: true
            },
            {
              field: 'imgUrl',
              label: '背景图片',
              type: 'upload-pic-card',
              required: true
            },
            {
              field: 'imgHeight',
              label: '图片高度',
              type: 'number'
            },
            {
              field: 'imgWidth',
              label: '图片宽度',
              type: 'number'
            },
            // {
            //   field: 'qrcodeUrl',
            //   label: '二维码',
            //   type: 'upload-pic-card',
            //   required: true
            // },
            {
              field: 'remark',
              label: '备注'
            },
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.facade.createQrcode },
        update: { action: https?.facade.updateQrcode },
        delete: { action: https?.facade.deleteQrcode }
      }
    };
    return { tableOpts };
  }
});
